import React from 'react'
import { Line, Bar, Pie } from 'react-chartjs-2'
import useDarkMode from 'use-dark-mode'

const LineChart = (props) => {
	const darkMode = useDarkMode(false)

	const pieOptions = {
		responsiveAnimationDuration: 0,
		tooltips: { enabled: true },
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		animation: {
			animateRotate: true
		},
		
	}
	const options = {
		responsiveAnimationDuration: 0, // animation duration after a resize
		tooltips: { enabled: true },
		hover: { mode: null },
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						stepSize: 5,
					},
					display: true,

					angleLines: {
						color: 'transparent',
					},
				},
			],
			xAxes: [
				{
					display: true,
				},
			],
		},
	}
	return (
		<>
			<div className='row'>
				{props.data.map((d) => {
					return (
						<div
							key={d.text}
							className={`col ${props.isDetailPage === true ? 's12' : 's6'}`}
						>
							<div className='card card-shadow z-depth-0'>
								<div className='card-info'>
									<div className='col s12'>
										<h6>{d.text}</h6>
										<h2>{d.count}</h2>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<div className='row chart-overview'>
				{props.data.map((d) => {
					if (darkMode.value) {
						if (!d.isPie) {
							d.values.datasets[0].backgroundColor = '#cfcfcf'
							d.values.datasets[0].borderColor = '#cfcfcf'
						} else {
							d.values.datasets[0].backgroundColor = ['#cfcfcf', '#676767']
							d.values.datasets[0].borderColor = ['#cfcfcf', '#676767']
						}
					} else {
						if (!d.isPie) {
							d.values.datasets[0].backgroundColor = '#2C3A51'
							d.values.datasets[0].borderColor = '#2C3A51'
						} else {
							d.values.datasets[0].backgroundColor = ["#4CAF50", "#F44336"]
							d.values.datasets[0].borderColor = ["#4CAF50", "#F44336"]
						}
					}
					return d.isPie ? (
						<div
							key={d.text}
							className={`col ${props.isDetailPage === true ? 's12' : 's6'}`}
						>
							<Pie data={d.values} options={pieOptions} />
						</div>
					) : (
							<div
								key={d.text}
								className={`col ${props.isDetailPage === true ? 's12' : 's6'}`}
							>
								<Line data={d.values} options={options} />
							</div>
						)
				})}
			</div>
		</>
	)
}

const BarChart = (props) => {
	const darkMode = useDarkMode(false)

	const options = {
		responsiveAnimationDuration: 0, // animation duration after a resize
		tooltips: { enabled: true },
		maintainAspectRatio: false,
		onClick: props.isDetailedActivityPage ? (e, element) => {
			if (element.length > 0) props.changeDataSource(element[0]._model.label)
			else props.changeDataSource()
		} : false,
		legend: {
			display: false,
		},
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						stepSize: 5,
					},
					display: true,
					angleLines: {
						color: 'transparent',
					},
				},
			],
			xAxes: [
				{
					display: true,
				},
			],
		},
	}
	return (
		<>
			<div className='row'>
				{props.data.map((d) => {
					return (
						<div
							key={d.text}
							className={`col ${props.isDetailPage === true ? 's12' : 's6'}`}
						>
							<div className='card card-shadow z-depth-0'>
								<div className='card-info'>
									<div className='col s12'>
										<h6>{d.text}</h6>
										<h2>{d.count}</h2>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<div className='row chart-overview'>
				{props.data.map((d) => {
					if (darkMode.value) {
						d.values.datasets[0].backgroundColor = '#cfcfcf'
						d.values.datasets[0].borderColor = '#cfcfcf'
					} else {
						d.values.datasets[0].backgroundColor = '#2C3A51'
						d.values.datasets[0].borderColor = '#2C3A51'
					}
					return (
						<div
							key={d.text}
							className={`col ${props.isDetailPage === true ? 's12' : 's6'}`}
						>
							<Bar data={d.values} options={options} />
						</div>
					)
				})}
			</div>
		</>
	)
}

const PieChart = (props) => {
	const darkMode = useDarkMode(false)

	const options = {
		responsiveAnimationDuration: 0, // animation duration after a resize
		tooltips: { enabled: true },
		maintainAspectRatio: true,
		legend: {
			display: false
		},
		animation: {
			animateRotate: true
		}
	}
	return (
		<>
			<div className='row'>
				{props.data.map((d) => {
					return (
						<div
							key={d.text}
							className={`col ${props.isDetailPage === true ? 's12' : 's6'}`}
						>
							<div className='card card-shadow z-depth-0'>
								<div className='card-info'>
									<div className='col s12'>
										<h6>{d.text}</h6>
										<h2>{d.count}</h2>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<div className='chart-overview'>
				{props.data.map((d) => {
					if (darkMode.value) {
						d.values.datasets[0].backgroundColor = ['#cfcfcf', '#676767']
						d.values.datasets[0].borderColor = ['#cfcfcf', '#676767']

					} else {
						d.values.datasets[0].backgroundColor = ["#4CAF50", "#F44336"]
						d.values.datasets[0].borderColor = ["#4CAF50", "#F44336"]
					}
					return (
						<div
							key={d.text}
							className={`col ${props.isDetailPage === true ? 's12' : 's6'}`}
						>
							<Pie data={d.values} options={options} />
						</div>
					)
				})}
			</div>
		</>
	)
}


export { LineChart, BarChart, PieChart }