import { GET_USER, UPDATED_USER, UPDATED_USER_ERROR } from '../actions/types'

export default (state = null, action) => {
	switch (action.type) {
		case GET_USER:
			return action.payload
		case UPDATED_USER:
			return { ...action.payload, success: 'Successfully updated user' }
		case UPDATED_USER_ERROR:
			return { ...state, error: action.payload }
		default:
			return state
	}
}
