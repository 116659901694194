import React from 'react'
import Table from './Table'

const List = ({ pageSize, columns, data, actions }) => {
	return (
		<div className='row'>
			<div className='col s12'>
				<Table
					title='Control Panel'
					pageSize={pageSize}
					columns={columns}
					data={data}
					actions={actions}
				/>
			</div>
		</div>
	)
}

export default List
