import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Overview from './Overview'
import Customers from './Customers'
import Menu from '../UI/Menu'
import Spinner from '../UI/Spinner'
import * as actions from '../../store/actions'

const Controller = ({
  location,
  currentTab,
  changeTab,
  getAllUsers,
  users,
  errors,
}) => {
  const tabs = ['Overview', 'Customers']

  const getData = useCallback(() => {
    getAllUsers()
  }, [getAllUsers])

  useEffect(getData, [])

  useEffect(() => {
    const currentTab = location.hash.replace('#', '')
    if (currentTab !== '') {
      if (tabs.map((t) => t.toLowerCase()).includes(currentTab))
        changeTab(currentTab)
    } else changeTab('overview')
  }, [changeTab, location.hash, tabs])

  const renderViews = () => {
    switch (currentTab) {
      case 'overview':
        return <Overview />
      case 'customers':
        return <Customers />
      default:
        return <Overview />
    }
  }

  if (errors !== null) return <div className='container'>No users</div>

  return users.length !== 0 ? (
    <div className='container'>
      <Menu
        title='Customers'
        description='Explore all Customers'
        menuItems={tabs}
      />
      {renderViews()}
    </div>
  ) : (
    <Spinner />
  )
}

const mapStateToProps = ({ users, currentTab, errors }) => {
  return { users, currentTab, errors }
}

export default connect(mapStateToProps, actions)(Controller)
