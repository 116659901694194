import React from 'react'
import { connect } from 'react-redux'
import List from '../UI/List'

const Sandboxes = ({ sandboxes }) => {
	return (
		<List
			pageSize={10}
			columns={[
				{ title: 'ID', field: 'sandboxId' },
				{ title: 'Namespace', field: 'namespace' },
				{ title: 'Customer', field: 'owner.userName' },
				{
					title: 'Provisioned',
					field: 'provisioned',
					lookup: {
						false: (
							<span
								className='material-icons red-text'
								style={{ marginRight: '20px' }}
							>
								fiber_manual_record
							</span>
						),
						true: (
							<span
								className='material-icons green-text'
								style={{ marginRight: '20px' }}
							>
								fiber_manual_record
							</span>
						),
					},
					customSort: (a, b) => a.active - b.active,
					align: 'center',
				},
			]}
			data={sandboxes}
			actions={'delete'}
		/>
	)
}

const mapStateToProps = ({ sandboxes }) => {
	return { sandboxes }
}

export default connect(mapStateToProps)(Sandboxes)
