import React from 'react'
import { withRouter } from 'react-router-dom'

const NotFound = (props) => {
	return (
		<div className='container'>
			<div className='center-align'>
				<div className='notfound'>
					<div className='404'>
						<h1 className='notfound-404'>404</h1>
					</div>
					<h2>Oops! Nothing was found</h2>
					<p>
						The page you are looking for might have been removed, had its name
						changed or is temporarily unavailable.
					</p>
					<button className='btn grey' onClick={props.history.goBack}>
						Go back
					</button>
				</div>
			</div>
		</div>
	)
}

export default withRouter(NotFound)
