import { combineReducers } from 'redux'
import { reducer as auth } from 'redux-oidc'
import sandboxes from './sandboxesReducer'
import users from './usersReducer'
import currentSandbox from './currentSandboxReducer'
import sandboxesOfUser from './sandboxesOfUser'
import currentUser from './currentUserReducer'
import currentTab from './currentTabReducer'
import sentryErrors from './sentryErrorsReducer'
import errors from './errorsReducer'
import previewEmail from './previewEmailReducer'
import emailSent from './emailSentReducer'
import detailedSandboxActivity from './detailedSandboxActivityReducer'
import availableTags from './availableTagsReducer'

export default combineReducers({
  auth,
  sandboxes,
  users,
  currentSandbox,
  sandboxesOfUser,
  currentUser,
  currentTab,
  sentryErrors,
  errors,
  previewEmail,
  emailSent,
  detailedSandboxActivity,
  availableTags
})
