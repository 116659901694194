import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Spinner from '../UI/Spinner';
import TopMenu from '../UI/TopMenu';
import {BarChart} from '../UI/Chart';
import NotFound from '../UI/NotFound';
import './Sandboxes.css';

class DetailedSandboxActivity extends Component {
    state = { isLoading: true, activityExists: true, key: "Overview" };

    componentDidMount = () => {
        this.getCurrentActivity();
    };


    getCurrentActivity = () => {
        const ID = this.props.match.params.id;

        this.props
            .sandboxActivityOverview(ID)
            .then((_) => this.customizeChart())
            .catch((_) => this.setState({ activityExists: false, isLoading: false }));
    };


    customizeChart = () => {
        let { overview } = this.props.detailedSandboxActivity;
        let data = []
        for (const [key, value] of Object.entries(overview)) {
            data.push({
                label: key,
                value: value
            });
        }
        this.labels = data.map((a) => a.label)
        this.chart_data = data.map((a) => a.value)
        this.setState({ isLoading: false, key: "Overview" });
    };

    changeDataSource = (endpoint) => {
        let { key } = this.state;
        if (key === "Overview" && endpoint ) {
            let { activityPerEndpoint } = this.props.detailedSandboxActivity;
            let data = []
            for (const [key, value] of Object.entries(activityPerEndpoint)) {
                data.push({
                    label: key,
                    value: value
                })
            }
            this.labels = data.filter((a) => a.label.includes(endpoint + "Controller")).map((a) => a.label.split(".")[4])
            this.chart_data = data.filter((a) => a.label.includes(endpoint + "Controller")).map((a) => a.value)
            this.setState({ key: endpoint })
        } else {
            this.customizeChart()
        }
    };


    labels = [];
    chart_data = [];

    render() {
        let { activityExists, isLoading } = this.state;
        if (!activityExists) return <NotFound />;
        if (isLoading) return <Spinner />;
        let { detailedSandboxActivity } = this.props;

        return detailedSandboxActivity!== null ? (
            <>
                <div className='container'>
                    <div className='row' style={{ marginBottom: '50px' }}>
                        <TopMenu
                            title={`Sandbox #${this.props.match.params.id}`}
                            currentDetailPage="Detailed API Activity"
                        />
                        <div className='col s12'>
                            <h4 className='menu-header'>Total sandbox activity</h4>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col s12'>
                            <BarChart
                                data={[
                                    {
                                        text: 'Activity overview',
                                        values: {
                                            labels: this.labels,
                                            datasets: [
                                                {
                                                    label: 'Total activity',
                                                    data: this.chart_data,
                                                },
                                            ],
                                        },
                                    },
                                ]}
                                isDetailPage={true}
                                isDetailedActivityPage={true}
                                changeDataSource = {this.changeDataSource}
                            />
                        </div>
                    </div>
                </div>


            </>
        ) : (
                <Spinner />
            );
    }
}



const mapStateToProps = ({ detailedSandboxActivity}) => {
    return { detailedSandboxActivity };
}


export default connect(mapStateToProps, actions)(DetailedSandboxActivity);