import { GET_ALL_SANDBOXES, DELETE_SANDBOX } from '../actions/types'

export default (state = [], action) => {
	switch (action.type) {
		case GET_ALL_SANDBOXES:
			return action.payload.items
		case DELETE_SANDBOX:
			return [...state.filter((s) => s.sandboxId !== action.payload)]
		default:
			return state
	}
}
