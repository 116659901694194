import { EMAIL_SENT, EMAIL_SENT_ERROR } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case EMAIL_SENT:
      return { success: 'Email sent successfully' }
    case EMAIL_SENT_ERROR:
      return { error: 'Error while sending emails' }
    default:
      return state
  }
}
