import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Email from './Email'
import Menu from '../UI/Menu'
import * as actions from '../../store/actions'

const Controller = ({ location, changeTab, errors }) => {
  const tabs = []

  useEffect(() => {
    const currentTab = location.hash.replace('#', '')
    if (currentTab !== '') {
      if (tabs.map((t) => t.toLowerCase()).includes(currentTab))
        changeTab(currentTab)
    } else changeTab('Send Email')
  }, [changeTab, location.hash, tabs])

  if (errors !== null)
    return <div className='container'>Failed to load Email Hub</div>

  return (
    <div className='container'>
      <Menu
        title='Email Hub'
        description='Send emails to customers'
        menuItems={tabs}
      />
      <Email />
    </div>
  )
}

const mapStateToProps = ({ users, currentTab, errors }) => {
  return { users, currentTab, errors }
}

export default connect(mapStateToProps, actions)(Controller)
