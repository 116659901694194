import React from 'react'
import TopMenu from './TopMenu'
import MenuLink from './MenuLink'

const Menu = ({ title, description, menuItems }) => {
	return (
		<>
			<div className='row' style={{ marginBottom: '50px' }}>
				<TopMenu title={title} />
				<div className='col s12'>
					<h4 className='menu-header'>{title}</h4>
					<h6 className='menu-subheader'>{description}</h6>
				</div>
			</div>
			<div className='row submenu' style={{ marginTop: '-20px' }}>
				{menuItems.map((item) => (
					<MenuLink key={item} name={item} />
				))}
			</div>
		</>
	)
}

export default Menu
