import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import TopMenu from '../UI/TopMenu'
import Spinner from '../UI/Spinner'
import Table from '../UI/Table'
import NotFound from '../UI/NotFound'
import { successNotyf, errorNotyf } from '../UI/Notification'
import moment from 'moment'
import './Customers.css'

const DetailedCustomer = ({
  getUser,
  sandboxesOfUser,
  getSandboxOfUser,
  currentUser,
  match,
  putUser,
  errors,
}) => {
  const columns = [
    { title: 'ID', field: 'sandboxId' },
    { title: 'Namespace', field: 'namespace' },
    { title: 'Created at', field: 'createdAt' },
    { title: 'Latest Activity', field: 'latestActivity' },
    {
      title: 'Provisioned',
      field: 'provisioned',
      lookup: {
        false: (
          <span
            className='material-icons red-text'
            style={{ marginRight: '20px' }}
          >
            fiber_manual_record
          </span>
        ),
        true: (
          <span
            className='material-icons green-text'
            style={{ marginRight: '20px' }}
          >
            fiber_manual_record
          </span>
        ),
      },
      customSort: (a, b) => a.provisioned - b.provisioned,
      align: 'center',
    },
    {
      title: 'Active',
      field: 'active',
      lookup: {
        false: (
          <span
            className='material-icons red-text'
            style={{ marginRight: '20px' }}
          >
            fiber_manual_record
          </span>
        ),
        true: (
          <span
            className='material-icons green-text'
            style={{ marginRight: '20px' }}
          >
            fiber_manual_record
          </span>
        ),
      },
      customSort: (a, b) => a.active - b.active,
      align: 'center',
    },
  ]

  const getData = useCallback(() => {
    getUser(match.params.id)
  }, [getUser, match.params.id])

  useEffect(getData, [])

  useEffect(() => {
    if (currentUser && sandboxesOfUser.length === 0) {
      for (let i in currentUser.sandboxes) {
        getSandboxOfUser(currentUser.sandboxes[i].sandboxId)
      }
    }
  })

  useEffect(() => {
    if (currentUser !== null) {
      if (currentUser.error) {
        errorNotyf(currentUser.error)
        delete currentUser.error
        getData()
      }

      if (currentUser.success) {
        successNotyf(currentUser.success)
        delete currentUser.success
      }
    }
  })

  const mapSandboxesOfUsersToTable = (sandboxes) => {
    return sandboxes.map((s) => {
      const isActive =
        s.sandboxStatus.dbHasData &&
        s.sandboxStatus.hasCertificate &&
        s.sandboxStatus.podAlive

      const latestActivity = s.sandboxStatus.latestActivity
        ? moment(
            s.sandboxStatus.latestActivity[
              s.sandboxStatus.latestActivity.length - 1
            ]
          ).format('MMMM Do YYYY, HH:mm:ss')
        : null

      return {
        sandboxId: s.sandboxId,
        namespace: s.randomNS,
        createdAt: moment(s.sandboxStatus.podCreated).format(
          'MMMM Do YYYY, HH:mm:ss'
        ),
        latestActivity: latestActivity,
        provisioned: s.provisioned,
        active: isActive,
      }
    })
  }

  const handleToggleRequested = () => {
    if (currentUser.id != null) {
      const updatedUser = { ...currentUser }
      updatedUser.requestedProduction = !updatedUser.requestedProduction
      putUser(currentUser.id, updatedUser)
    }
  }

  const toggleEmailConfirmed = () => {
    if (currentUser.id != null) {
      const updatedUser = { ...currentUser }
      updatedUser.emailConfirmed = !updatedUser.emailConfirmed
      putUser(currentUser.id, updatedUser)
    }
  }

  const togglePhoneConfirmed = () => {
    if (currentUser.id != null) {
      const updatedUser = { ...currentUser }
      updatedUser.phoneNumberConfirmed = !updatedUser.phoneNumberConfirmed
      putUser(currentUser.id, updatedUser)
    }
  }

  const copyToClipboard = (str) => {
    document.execCommand('copy')

    if (window.getSelection) {
      window.getSelection().removeAllRanges()
    } else if (document.selection) {
      document.selection.empty()
    }
    successNotyf(str + ' copied to clipboard')
  }

  if (errors !== null) return <NotFound />

  return currentUser !== null ? (
    <div className='container'>
      <div className='row' style={{ marginBottom: '50px' }}>
        <TopMenu
          title='Customers'
          currentDetailPage={`${currentUser.firstName} ${currentUser.lastName}`}
        />
        <div className='col s12'>
          <h4 className='menu-header'>
            {currentUser.firstName} {currentUser.lastName}
          </h4>
          <h6 className='menu-subheader'>{currentUser.email}</h6>
        </div>
      </div>
      <div className='row' style={{ marginBottom: 0 }}>
        <div className='col s12'>
          <div className='card-panel details-card'>
            <div className='d-flex'>
              <div className='flex-grow-3'>
                <h6>Production Requested</h6>
                <blockquote
                  className={`blockquote${
                    currentUser.requestedProduction ? '-success' : ''
                  }`}
                >
                  {currentUser.company} has{' '}
                  {currentUser.requestedProduction ? '' : 'not (yet) '}
                  requested production.
                </blockquote>
              </div>
              <div className='d-flex align-items-center flex-grow-1 justify-content-end'>
                <a
                  href='#production-modal'
                  className='chip theme-chip-button waves-effect waves-light modal-trigger'
                  style={{
                    position: 'relative',
                    top: '10px',
                    marginRight: '2rem',
                  }}
                >
                  Show Info
                </a>

                <div id='production-modal' className='modal'>
                  <div className='modal-content'>
                    <button
                      type='button'
                      className='btn-modal-close modal-close'
                      aria-label='Close'
                    >
                      <span aria-hidden='true'>×</span>
                    </button>
                    <div
                      style={{
                        marginTop: '1.5rem',
                        marginBottom: '1.5rem',
                      }}
                    >
                      {currentUser.requestedProduction ? (
                        <div>
                          <h5>Production Requested</h5>
                          <br />
                          <p>
                            <span style={{ fontWeight: '700' }}>
                              {currentUser.company} has requested production
                            </span>
                            <br />
                            {currentUser.company} has submitted a request
                            through the developer portal to learn more about
                            ZData's products.
                            <br />
                            Please contact them with the contact information
                            provided below.
                          </p>
                          <table className='production-table highlight'>
                            <tbody>
                              <tr
                                style={{
                                  borderBottom: '0px solid rgba(0,0,0,0.12)',
                                }}
                              >
                                <td>
                                  <i className='material-icons'>phone</i>
                                </td>
                                <td
                                  className='selectable-bg'
                                  style={{
                                    userSelect: 'all',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    copyToClipboard('Phone number')
                                  }
                                >
                                  {currentUser.phoneNumber}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <i className='material-icons'>email</i>
                                </td>
                                <td
                                  className='selectable-bg'
                                  style={{
                                    userSelect: 'all',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => copyToClipboard('Email')}
                                >
                                  {currentUser.email}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            style={{
                              marginTop: '1.5rem',
                              marginBottom: '2rem',
                            }}
                          >
                            <p>
                              Whenever you are done, switch "Production
                              Requested" to false to mark as resolved.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h5>Production Requested</h5>
                          <br />
                          <p>
                            <span style={{ fontWeight: '700' }}>
                              {currentUser.company} has not requested production
                            </span>
                          </p>
                        </div>
                      )}
                    </div>

                    <div className='d-flex flex-wrap'>
                      <div className='chip chip-info'>Production Requested</div>

                      <div className='switch' style={{ marginLeft: '2rem' }}>
                        <label>
                          {currentUser.requestedProduction ? (
                            <span className='material-icons'>close</span>
                          ) : (
                            <span className='material-icons red-text'>
                              cancel
                            </span>
                          )}
                          <input
                            className='checkbox-darkmode'
                            type='checkbox'
                            checked={currentUser.requestedProduction}
                            onChange={() => handleToggleRequested()}
                          />
                          <span className='lever'></span>
                          {currentUser.requestedProduction ? (
                            <span className='material-icons green-text'>
                              check_circle
                            </span>
                          ) : (
                            <span className='material-icons'>done</span>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s6'>
          <div className='card details-card' style={{ paddingBottom: '61px' }}>
            <div className='card-info'>
              <div className='col s12'>
                <h6>Customer</h6>
                <br />
                <div className='row'>
                  <div className='input-field col s12'>
                    <i className='material-icons prefix'>perm_identity</i>
                    <input
                      disabled
                      value={currentUser.id}
                      type='text'
                      className='validate'
                    />
                    <label className='active'>ID</label>
                  </div>
                  <div className='input-field col s12'>
                    <i className='material-icons prefix'>business</i>
                    <input
                      disabled
                      value={currentUser.company}
                      type='text'
                      className='validate'
                    />
                    <label className='active'>Company</label>
                  </div>
                  <div className='input-field col s12'>
                    <i className='material-icons prefix'>contacts</i>
                    <input
                      disabled
                      value={currentUser.role}
                      type='text'
                      className='validate'
                    />
                    <label className='active'>Role</label>
                  </div>
                  <div className='input-field col s12'>
                    <i className='material-icons prefix'>event</i>
                    <input
                      disabled
                      value={moment(currentUser.createdDate).format(
                        'MMMM Do YYYY, HH:mm:ss'
                      )}
                      type='text'
                      className='validate'
                    />
                    <label className='active'>Date Created</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col s6'>
          <div className='card details-card'>
            <div className='card-info'>
              <div className='col s12'>
                <h6>Contact</h6>
                <br />
                <div className='row'>
                  <div className='input-field col s12'>
                    <i className='material-icons prefix'>email</i>
                    <input
                      disabled
                      value={currentUser.email}
                      type='text'
                      className='validate'
                    />
                    <label className='active'>Email</label>
                  </div>
                  <div className='input-field col s12'>
                    <i className='material-icons prefix'>phone</i>
                    <input
                      disabled
                      value={`+${currentUser.phoneNumber}`}
                      type='text'
                      className='validate'
                    />
                    <label className='active'>Phone Number</label>
                  </div>
                </div>
                {currentUser.acceptedEmails ? (
                  <div className='d-flex align-items-center flex-grow-1 justify-content-end'>
                    <a
                      href='#email-modal'
                      className='chip theme-chip-button waves-effect waves-light modal-trigger'
                      style={{
                        position: 'relative',
                        top: '10px',
                        marginRight: '0.5rem',
                      }}
                    >
                      Send Email
                    </a>

                    <div id='email-modal' className='modal'>
                      <div className='modal-content'>
                        <button
                          type='button'
                          className='btn-modal-close modal-close'
                          aria-label='Close'
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                        <div
                          style={{
                            marginTop: '1.5rem',
                            marginBottom: '1.5rem',
                          }}
                        >
                          <div>
                            <h5>Send Email</h5>
                            <br />
                            <p>TODO</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='card details-card'>
            <div className='card-info'>
              <div className='col s12'>
                <h6>Status</h6>
                <br />
                <div className='row d-flex flex-wrap justify-content-between'>
                  <div className='chip chip-info'>Email Confirmed</div>

                  <div className='switch'>
                    <label>
                      {currentUser.emailConfirmed ? (
                        <span className='material-icons'>close</span>
                      ) : (
                        <span className='material-icons red-text'>cancel</span>
                      )}
                      <input
                        className='checkbox-darkmode'
                        type='checkbox'
                        checked={currentUser.emailConfirmed}
                        onChange={() => toggleEmailConfirmed()}
                      />
                      <span className='lever'></span>
                      {currentUser.emailConfirmed ? (
                        <span className='material-icons green-text'>
                          check_circle
                        </span>
                      ) : (
                        <span className='material-icons'>done</span>
                      )}
                    </label>
                  </div>
                </div>
                <div className='row d-flex flex-wrap justify-content-between'>
                  <div className='chip chip-info'>Phone Confirmed</div>

                  <div className='switch'>
                    <label style={{ float: 'right' }}>
                      {currentUser.phoneNumberConfirmed ? (
                        <span className='material-icons'>close</span>
                      ) : (
                        <span className='material-icons red-text'>cancel</span>
                      )}
                      <input
                        className='checkbox-darkmode'
                        type='checkbox'
                        checked={currentUser.phoneNumberConfirmed}
                        onChange={() => togglePhoneConfirmed()}
                      />
                      <span className='lever'></span>
                      {currentUser.phoneNumberConfirmed ? (
                        <span className='material-icons green-text'>
                          check_circle
                        </span>
                      ) : (
                        <span className='material-icons'>done</span>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Table
            pageSize={3}
            columns={columns}
            data={mapSandboxesOfUsersToTable(sandboxesOfUser)}
            title='Sandboxes'
            isCustomerPage={true}
          />
        </div>
      </div>
      )
    </div>
  ) : (
    <Spinner />
  )
}

const mapStateToProps = ({ currentUser, sandboxesOfUser, errors }) => {
  return { currentUser, sandboxesOfUser, errors }
}

export default connect(mapStateToProps, actions)(DetailedCustomer)
