import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Menu from '../UI/Menu'
import Spinner from '../UI/Spinner'
import DashboardOverview from './Overview'
import * as actions from '../../store/actions'

const Controller = ({
	location,
	changeTab,
	getAllSandboxes,
	getAllUsers,
	getSentryErrors,
	sandboxes,
	users,
	sentryErrors,
	errors,
}) => {
	const tabs = ['Overview']

	const getData = useCallback(() => {
		getAllSandboxes()
		getAllUsers()
		getSentryErrors()
	}, [getAllSandboxes, getAllUsers, getSentryErrors])

	useEffect(getData, [])

	useEffect(() => {
		const currentTab = location.hash.replace('#', '')
		if (currentTab !== '') {
			if (tabs.map((t) => t.toLowerCase()).includes(currentTab))
				changeTab(currentTab)
		} else changeTab('overview')
	}, [changeTab, location.hash, tabs, getData])

	if (errors !== null) return <div className='container'>No sandboxes and/or users</div>

	return sandboxes.length !== 0 &&
		users.length !== 0 ? (
		//sentryErrors.length !== 0 ? 
		<div className='container'>
			<Menu
				title='Dashboard'
				description='So, this is the latest status...'
				menuItems={tabs}
			/>
			<DashboardOverview />
		</div>
	) : (
		<Spinner />
	)
}

const mapStateToProps = ({ sandboxes, users, sentryErrors, errors }) => {
	return { sandboxes, users, sentryErrors, errors }
}

export default connect(mapStateToProps, actions)(Controller)
