import React, { useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Gravatar from 'react-gravatar'
import DarkMode from '../UI/DarkMode'
import userManager from '../../tools/userManager'
import M from 'materialize-css/dist/js/materialize.min.js'
import './UI.css'

const Navbar = ({ auth }) => {
	useEffect(() => {
		M.AutoInit()
	})

	if (auth.user == null) {
		return <p>Error authenticating, relog on your provider.</p>
	}
	const { profile } = auth.user
	return (
		<>
			<nav className='z-depth-0'>
				<Link to='#' data-target='slide-out' className='sidenav-trigger'>
					<i className='material-icons menu-icon'>menu</i>
				</Link>
			</nav>
			<ul id='slide-out' className='sidenav sidenav-fixed'>
				<li>
					<div className='row sidenav-configure'>
						<div className='settings-icon'>
							<DarkMode />
						</div>
						<div className='logout-icon'>
							<Link to='#' onClick={() => userManager.signoutRedirect()}>
								<button className='chip theme-chip-button waves-effect waves-light'>
									Log out
								</button>
							</Link>
						</div>
					</div>
					<div className='user-view center-align'>
						<Gravatar
							className='circle img-avatar'
							email={profile.email}
							size={100}
						/>
						<span className='name'>{profile.full_name}</span>
						<span className='email'>{profile.email}</span>
					</div>
				</li>
				<li>
					<Link to='/' href className='subheader'>
						Developer Portal
					</Link>
				</li>
				<li>
					<NavLink
						exact
						to='/'
						activeClassName='active'
						className='sidenav-item'
					>
						<i className='material-icons'>dashboard</i>Dashboard
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/sandboxes'
						activeClassName='active'
						className='sidenav-item'
					>
						<i className='material-icons'>storage</i>Sandboxes
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/customers'
						activeClassName='active'
						className='sidenav-item'
					>
						<i className='material-icons'>group</i>Customers
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/email'
						activeClassName='active'
						className='sidenav-item'
					>
						<i className='material-icons'>email</i>Email Hub
					</NavLink>
				</li>
			</ul>
		</>
	)
}

const mapStateToProps = ({ auth }) => {
	return { auth }
}

export default connect(mapStateToProps)(Navbar)
