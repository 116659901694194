import React from 'react'
import { CallbackComponent } from 'redux-oidc'
import Spinner from '../components/UI/Spinner'
import userManager from './userManager'

const successCallback = (user, history) => {
	history.push(user.state.path)
}

const errorCallback = (error) => {
	console.error('errorCallback', error)
}

const AuthCallback = (props) => {
	return (
		<CallbackComponent
			userManager={userManager}
			successCallback={(user) => successCallback(user, props.history)}
			errorCallback={errorCallback}
		>
			<Spinner />
		</CallbackComponent>
	)
}

export default AuthCallback
