import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export const successNotyf = (text) =>
	new Notyf({
		duration: 3000,
		position: { x: 'center', y: 'top' },
	}).success(text)

export const errorNotyf = (text) =>
	new Notyf({
		duration: 3000,
		position: { x: 'center', y: 'top' },
	}).error(text)
