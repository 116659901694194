import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NewestCustomerCard from './NewestCustomerCard';
import LatestErrorCard from './LatestErrorCard';
import moment from 'moment';
import Card from '../UI/Card';
import './Dashboard.css';

const Overview = ({ sandboxes, users, sentryErrors }) => {
  const requestedProductionCount = users.filter(
    (obj) => obj.requestedProduction === true
  ).length;

  return (
    <>
      <div className='row d-flex align-items-stretch flex-wrap'>
        <div className='col s12 m4 d-flex align-items-stretch'>
          <Card title='Total Sandboxes' data={sandboxes.length} inverted />
        </div>
        <div className='col s12 m4 d-flex align-items-stretch'>
          <Card
            title='New this week'
            data={
              users.filter((u) => {
                const now = moment();
                const date = moment(u.createdAt);
                return now.isoWeek() === date.isoWeek();
              }).length
            }
          />
        </div>
        <div className='col s12 m4 d-flex align-items-stretch'>
          <Card title='Total Customers' data={users.length} inverted />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card-panel'>
            <div className='d-flex'>
              <div className='flex-grow-3'>
                <h6>Production Requested</h6>
                <blockquote className={'blockquote'}>
                  {requestedProductionCount !== 1
                    ? `${requestedProductionCount} new customers have requested production.`
                    : `${requestedProductionCount} new customer has requested production.`}
                </blockquote>
              </div>
              <div className='d-flex align-items-center flex-grow-1 justify-content-end'>
                <Link
                  to='/customers'
                  className='chip theme-chip-button waves-effect waves-light'
                  style={{
                    position: 'relative',
                    top: '10px',
                    marginRight: '2rem',
                  }}
                >
                  Show customers
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sentryErrors.length !== 0 && Array.isArray(sentryErrors) ? (
        <>
          <div className='row d-flex align-items-stretch flex-wrap'>
            <div className='col s12 m4 d-flex align-items-stretch'>
              <Card
                title='Error Count (24 hours)'
                data={
                  sentryErrors.filter((e) => {
                    const now = moment();
                    const date = moment(e.lastSeen);
                    return (
                      now.isSame(date, 'day') ||
                      now.subtract(24, 'h').isBefore(date)
                    );
                  }).length
                }
                error
              />
            </div>
            <div className='col s12 m8 d-flex align-items-stretch'>
              <NewestCustomerCard />
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <LatestErrorCard />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ sandboxes, users, sentryErrors }) => {
  return { sandboxes, users, sentryErrors };
};

export default connect(mapStateToProps)(Overview);
