import React from 'react'

const Chip = ({ text, inverted, error }) => {
	const color = () => {
		if (error) return 'danger'
		else if (inverted) return 'blue'
		else return 'success'
	}
	return <div className={`chip chip-${color()}`}>{text}</div>
}

export default Chip
