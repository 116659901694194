import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Overview from './Overview'
import Sandboxes from './Sandboxes'
import Errors from './Errors'
import Menu from '../UI/Menu'
import Spinner from '../UI/Spinner'
import * as actions from '../../store/actions'

const Controller = ({
	location,
	changeTab,
	currentTab,
	getAllSandboxes,
	getAllUsers,
	sandboxes,
	users,
	errors,
}) => {
	const tabs = ['Overview', 'Sandboxes', 'Errors']

	const getData = useCallback(() => {
		getAllSandboxes()
		getAllUsers()
	}, [getAllSandboxes, getAllUsers])

	useEffect(getData, [])

	useEffect(() => {
		const currentTab = location.hash.replace('#', '')
		if (currentTab !== '') {
			if (tabs.map((t) => t.toLowerCase()).includes(currentTab))
				changeTab(currentTab)
		} else changeTab('overview')
	}, [changeTab, location.hash, tabs])

	const renderViews = () => {
		switch (currentTab) {
			case 'overview':
				return <Overview />
			case 'sandboxes':
				return <Sandboxes />
			case 'errors':
				return <Errors />
			default:
				return <Overview />
		}
	}

	if (errors !== null) return <div className='container'>No sandboxes</div>

	return sandboxes.length !== 0 ? (
		<div className='container'>
			<Menu
				title='Sandboxes'
				description='Explore all Sandboxes'
				menuItems={tabs}
			/>
			{renderViews()}
		</div>
	) : (
		<Spinner />
	)
}

const mapStateToProps = ({ sandboxes, users, currentTab, errors }) => {
	return { sandboxes, users, currentTab, errors }
}

export default connect(mapStateToProps, actions)(Controller)
