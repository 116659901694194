import { GET_SENTRY_ERRORS } from '../actions/types'

export default (state = [], action) => {
	switch (action.type) {
		case GET_SENTRY_ERRORS:
			return action.payload
		default:
			return state
	}
}
