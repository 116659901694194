import React from 'react'
import CountUp from 'react-countup'

const Card = ({ title, data, inverted, error }) => {
	const textColor = () => {
		if (error) return 'danger'
		else if (inverted) return 'white'
		else return 'blue'
	}

	return (
		<div
			className={`card-panel w-100 card-underlined${error ? '-danger' : ''}  ${
				inverted ? 'card-inverted' : ''
			}`}
		>
			<h6>{title}</h6>
			<h2 className={`center-align card-text-${textColor()}`}>
				<CountUp start={0} end={data} duration={2.75} />
			</h2>
		</div>
	)
}

export default Card
