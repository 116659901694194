import React from 'react'
import useDarkMode from 'use-dark-mode'

const DarkMode = () => {
	const darkMode = useDarkMode(false)

	let value = darkMode.value

	return (
		<>
			<div className='switch'>
				<label>
					{value === true ? (
						<span className='material-icons'>wb_sunny</span>
					) : (
						<span className='material-icons yellow-text'>wb_sunny</span>
					)}
					<input
						className='checkbox-darkmode'
						type='checkbox'
						checked={value}
						onChange={darkMode.toggle}
					/>
					<span className='lever'></span>
					{value === false ? (
						<span className='material-icons'>nights_stay</span>
					) : (
						<span className='material-icons blue-text'>nights_stay</span>
					)}
				</label>
			</div>
		</>
	)
}

export default DarkMode
