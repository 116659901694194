import {
  GET_SANDBOX,
  CHANGE_SANDBOX_STATUS,
  SANDBOX_NOT_VERIFIED,
  GET_SANDBOX_FAILED,
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case GET_SANDBOX:
      return action.payload;
    case CHANGE_SANDBOX_STATUS:
      return {
        ...state,
        provisioned: action.payload.provisioned,
        provisioningStatus: action.payload.provisioningStatus,
        statusUpdate: Date.now(),
      };
    case SANDBOX_NOT_VERIFIED:
      return { ...state, provisioned: false, error: action.payload };
    case GET_SANDBOX_FAILED:
      return '404';
    default:
      return state;
  }
};
