import React from 'react'

const Spinner = (props) => {
	return (
		<div
			className={`${
				props.noPadding === true ? 'container no-padding' : 'container'
			}`}
		>
			<div className='spinner'>
				<div className='dot1'></div>
				<div className='dot2'></div>
			</div>
		</div>
	)
}

export default Spinner
