import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'

const TopMenu = ({ title, currentTab, changeTab, currentDetailPage }) => {
	return (
		<div className='col s12'>
			<Link to='/' className='breadcrumb'>
				Developer Portal
			</Link>
			<Link
				to='./#overview'
				id='overview'
				className='breadcrumb'
				onClick={(e) => changeTab(e.target.id)}
			>
				{title}
			</Link>
			<span className='breadcrumb'>
				{currentDetailPage === undefined ? currentTab : currentDetailPage}
			</span>
		</div>
	)
}

const mapStateToProps = ({ currentTab }) => {
	return { currentTab }
}

export default connect(mapStateToProps, actions)(TopMenu)
