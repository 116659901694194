import { GET_SANDBOX_OF_USER } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case GET_SANDBOX_OF_USER:
      return [...state, action.payload]
    default:
      return state
  }
}
