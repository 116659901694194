import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Chip from '../UI/Chip'

const LatestErrorCard = ({ sentryErrors }) => {
	const [error] = sentryErrors
	return (
		<div
			className='card-panel card-underlined-danger hoverable'
			onClick={() => window.open(error.permalink, '_blank')}
		>
			<div className='row'>
				<div className='col s6'>
					<h6>Latest Error</h6>
				</div>
				<div className='col s6'>
					<h6 className='right-align'>
						{moment(error.lastSeen).format('MMMM Do YYYY, h:mm:ss')}
					</h6>
				</div>
			</div>
			<blockquote className='blockquote-danger'>{error.title}</blockquote>
			<div className='card-chips'>
				<Chip text={error.shortId} inverted />
				<Chip text={error.platform} inverted />
				<Chip text={error.type} inverted />
				<Chip text={error.metadata.function} inverted />
				<Chip text={error.status} inverted />
				<Chip text={error.count} inverted />
			</div>
		</div>
	)
}

const mapStateToProps = ({ sentryErrors }) => {
	return { sentryErrors }
}

export default connect(mapStateToProps)(LatestErrorCard)
