import axios from '../../config/axios'
import {
  GET_ALL_SANDBOXES,
  GET_SANDBOX,
  GET_SANDBOX_OF_USER,
  POST_SANDBOX,
  DELETE_SANDBOX,
  CHANGE_SANDBOX_STATUS,
  SANDBOX_NOT_VERIFIED,
  GET_SANDBOX_CERTIFICATE,
  GET_SANDBOX_DBSTATUS,
  GET_SANDBOX_LASTACTIVITY,
  GET_SANDBOX_PODSTATUS,
  GET_ALL_USERS,
  GET_USER,
  CHANGE_CURRENT_TAB,
  GET_SENTRY_ERRORS,
  UPDATED_USER,
  UPDATED_USER_ERROR,
  EMAIL_SENT,
  EMAIL_SENT_ERROR,
  PREVIEW_EMAIL,
  PREVIEW_EMAIL_ERROR,
  API_ERROR,
  GET_SANDBOX_ACTIVITY,
  UPDATE_API,
  UPDATE_API_POD,
  GET_IMAGE_TAGS,
  CHANGE_BANK_ENGINE_STATUS,
  UPDATE_PROVISION
} from './types'

export const getAllSandboxes = () => async (dispatch) => {
  dispatch({ type: API_ERROR, payload: null })
  let res
  try {
    res = await axios.get('/api/v1/Sandboxes')
    dispatch({ type: GET_ALL_SANDBOXES, payload: res.data })
  } catch (e) {
    dispatch({
      type: API_ERROR,
      payload: e,
    })
  }
  return res
}

export const getSandbox = (id) => async (dispatch) => {
  let res
  try {
    res = await axios.get(`/api/v1/Sandboxes/${id}`)
    dispatch({ type: GET_SANDBOX, payload: res.data })
  } catch (e) {
    dispatch({
      type: API_ERROR,
      payload: e,
    })
  }
  return res
}

export const getSandboxOfUser = (id) => async (dispatch) => {
  let res
  try {
    res = await axios.get(`/api/v1/Sandboxes/${id}`)
    dispatch({ type: GET_SANDBOX_OF_USER, payload: res.data })
  } catch (e) {
    dispatch({
      type: API_ERROR,
      payload: e,
    })
  }
  return res
}

export const postSandbox = (userId, data) => async (dispatch) => {
  const res = await axios.post(`/api/v1/Sandboxes/${userId}`, data)

  dispatch({
    type: POST_SANDBOX,
    payload: res.data,
  })
}

export const deleteSandbox = (id) => async (dispatch) => {
  await axios.delete(`/api/v1/Sandboxes/${id}`)

  dispatch({
    type: DELETE_SANDBOX,
    payload: id,
  })
}

export const getSandboxPodstatus = (id) => async (dispatch) => {
  const res = await axios.get(`/api/v1/Sandboxes/${id}/podstatus`)

  dispatch({
    type: GET_SANDBOX_PODSTATUS,
    payload: res.data,
  })
}

export const getSandboxCertificate = (id) => async (dispatch) => {
  const res = await axios.get(`/api/v1/Sandboxes/${id}/certificate`)

  dispatch({
    type: GET_SANDBOX_CERTIFICATE,
    payload: res.data,
  })
}

export const getSandboxDbstatus = (id) => async (dispatch) => {
  const res = await axios.get(`/api/v1/Sandboxes/${id}/dbstatus`)

  dispatch({
    type: GET_SANDBOX_DBSTATUS,
    payload: res.data,
  })
}

export const getSandboxLastActivity = (id) => async (dispatch) => {
  const res = await axios.get(`/api/v1/Sandboxes/${id}/lastactivity`)

  dispatch({
    type: GET_SANDBOX_LASTACTIVITY,
    payload: res.data,
  })
}

export const getAllUsers = () => async (dispatch) => {
  dispatch({ type: API_ERROR, payload: null })
  let res
  try {
    res = await axios.get('/api/v1/Users')
    dispatch({ type: GET_ALL_USERS, payload: res.data })
  } catch (e) {
    dispatch({
      type: API_ERROR,
      payload: e.response.status + ' ' + e.response.statusText,
    })
  }
  return res
}

export const getUser = (id) => async (dispatch) => {
  let res
  try {
    res = await axios.get(`/api/v1/Users/${id}`)
    dispatch({ type: GET_USER, payload: res.data })
  } catch (e) {
    dispatch({
      type: API_ERROR,
      payload: e.response.status + ' ' + e.response.statusText,
    })
  }
  return res
}

export const putUser = (id, data) => async (dispatch) => {
  let res
  try {
    res = await axios.put(`/api/v1/Users/${id}`, data)
  } catch (e) {
    return dispatch({
      type: UPDATED_USER_ERROR,
      payload: e.message,
    })
  }

  dispatch({
    type: UPDATED_USER,
    payload: res.data,
  })
}

export const sendEmail = (emailSubject, emailContent, toAll) => async (
  dispatch
) => {
  let body = { emailSubject, emailContent, toAll }
  try {
    await axios.post(`/api/v1/Users/Email`, body)
  } catch (e) {
    return dispatch({
      type: EMAIL_SENT_ERROR,
    })
  }

  dispatch({
    type: EMAIL_SENT,
  })
}

export const getPreviewOfEmail = (emailSubject, emailContent, toAll) => async (dispatch) => {
  let res
  try {
    res = await axios.post(`/api/v1/Users/PreviewEmail`, { emailSubject: emailSubject, emailContent: emailContent, toAll: toAll })
  } catch (e) {
    return dispatch({
      type: PREVIEW_EMAIL_ERROR,
      payload: e.message,
    })
  }

  dispatch({
    type: PREVIEW_EMAIL,
    payload: res.data,
  })
}

export const changeTab = (tab) => {
  return {
    type: CHANGE_CURRENT_TAB,
    payload: tab,
  }
}

export const getSentryErrors = () => async (dispatch) => {
  const res = await axios.get('/api/v1/Sentry')

  dispatch({
    type: GET_SENTRY_ERRORS,
    payload: res.data,
  })
}

export const sandboxVerify = (id) => async (dispatch) => {
  let res
  try {
    res = await axios.put(`/api/v1/Sandboxes/Verify/${id}`)
    dispatch({ type: GET_SANDBOX, payload: res.data })
  } catch (_) {
    dispatch({
      type: SANDBOX_NOT_VERIFIED,
      payload: 'Sandbox is not working',
    })
  }
  return res
}

export const sandboxProvision = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Provision/${id}`)
  dispatch({
    type: CHANGE_SANDBOX_STATUS,
    payload: { provisioned: false, provisioningStatus: 5 },
  })
}

export const sandboxReprovision = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Reprovision/${id}`)
  dispatch({
    type: CHANGE_SANDBOX_STATUS,
    payload: {provisioned: false, provisioningStatus: 5}
  })
}

export const sandboxDelete = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Delete/${id}`)
  dispatch({
    type: CHANGE_SANDBOX_STATUS,
    payload: { provisioned: false, provisioningStatus: 10 },
  })
}

export const sandboxProvisionDatabase = (id) => async (dispatch) =>{
  await axios.put(`/api/v1/Sandboxes/Provision/Database/${id}`)
  dispatch({
    type: CHANGE_SANDBOX_STATUS,
    payload: {provisioned: false, provisioningStatus: 1}
  })
}

export const sandboxDeleteDatabase = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Delete/Database/${id}`)
  dispatch({
    type: CHANGE_SANDBOX_STATUS,
    payload: { provisioned: false, provisioningStatus: 6 },
  })
}

export const sandboxProvisionNamespace = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Provision/Namespace/${id}`)
  dispatch({
    type: CHANGE_SANDBOX_STATUS,
    payload: { provisioned: false, provisioningStatus: 2}
  })
}

export const sandboxDeleteNamespace = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Delete/Namespace/${id}`)
  dispatch({
    type: CHANGE_SANDBOX_STATUS,
    payload: { provisioned: false, provisioningStatus: 7 },
  })
}

export const sandboxCreateBankEngine = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Provision/BankEngine/${id}`)
  dispatch({
    type: CHANGE_BANK_ENGINE_STATUS,
    payload: `Bank Engine created for sandbox #${id}`
  })
}

export const sandboxDeleteBankEngine = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/Delete/BankEngine/${id}`)
  dispatch({
    type: CHANGE_BANK_ENGINE_STATUS,
    payload: `Bank Engine deleted for sandbox #${id}`
  })
}

export const sandboxActivityOverview = (id) => async (dispatch) => {
  let res
  try {
    res = await axios.get(`/api/v1/Sandboxes/GetActivity/${id}`)
    dispatch({ type: GET_SANDBOX_ACTIVITY, payload: res.data })
  } catch (e) {
    dispatch({
      type: API_ERROR,
      payload: e,
    })
  }
  return res
}

export const updateAPIPods = (serviceTag, migratorTag) => async (dispatch) => {
  let body = {serviceTag, migratorTag}
  await axios.put(`/api/v1/Sandboxes/UpdateAPI`, body)
  dispatch({ type: UPDATE_API })
}

export const updateAPIPod = (serviceTag, migratorTag, id) => async (dispatch) =>{
  let body = {serviceTag, migratorTag}
  await axios.put(`/api/v1/Sandboxes/UpdateAPI/${id}`, body)
  dispatch({type: UPDATE_API_POD})
}

export const getImageTags = () => async (dispatch) => {
  let res
  try {
    res = await axios.get(`/api/v1/Sandboxes/GetTags`)
    dispatch({ type: GET_IMAGE_TAGS, payload: res.data })
  } catch (e) {
    dispatch({
      type: API_ERROR,
      payload: e
    })
  }
  return res
}

export const updateProvision = (id) => async (dispatch) => {
  await axios.put(`/api/v1/Sandboxes/UpdateProvision/${id}`)
  dispatch({ type: UPDATE_PROVISION })
}
