import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { successNotyf, errorNotyf } from '../UI/Notification'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './Email.css'

const Email = ({ sendEmail, getPreviewOfEmail, previewEmail, emailSent }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [subjectState, setSubjectState] = useState('')
  const [toAll, setToAll] = useState(false)
  const [iframeHeight, setiframeHeight] = useState('0px')
  const iframe = useRef(null)

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const handleSendEmail = () => {
    const emailSubject = subjectState
    const emailContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )
    sendEmail(emailSubject, emailContent, toAll)
  }

  const handlePreviewEmail = () => {
    const emailContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )
    getPreviewOfEmail(subjectState, emailContent, toAll)
  }

  const onPreviewLoad = () => {
    setiframeHeight(
      iframe.current.contentWindow.document.body.clientHeight + 'px'
    )
  }

  useEffect(() => {
    if (previewEmail !== null) {
      if (previewEmail.error) {
        errorNotyf(previewEmail.error)
        delete previewEmail.error
      }
    }
    if (emailSent.success) {
      successNotyf(emailSent.success)
      delete emailSent.success
    }
    if (emailSent.error) {
      errorNotyf(emailSent.error)
      delete emailSent.error
    }
  })

  return (
    <div style={{ marginLeft: '1%', marginRight: '1%' }}>
      <div className='editor'>
        <div className='mailSubject'>
          <input
            placeholder='Add a subject'
            onChange={(event) => setSubjectState(event.target.value)}
          />
        </div>
        <Editor
          editorState={editorState}
          wrapperClassName='demo-wrapper'
          editorClassName='demo-editor'
          onEditorStateChange={handleEditorStateChange}
        />
        <div className='d-flex justify-content-between'>
          <a
            href='#preview-modal'
            className='chip theme-chip-button-grey waves-effect waves-light modal-trigger'
            onClick={() => handlePreviewEmail()}
          >
            Preview Email
          </a>

          {!previewEmail.error ? (
            <div id='preview-modal' className='modal'>
              <div className='modal-content'>
                <button
                  type='button'
                  className='btn-modal-close modal-close'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>×</span>
                </button>

                {previewEmail.email ? (
                  <iframe
                    ref={iframe}
                    onLoad={onPreviewLoad}
                    style={{ width: '100%', height: iframeHeight }}
                    title='preview'
                    srcDoc={previewEmail.email}
                    frameBorder='0'
                    scrolling='no'
                  />
                ) : (
                  'Loading...'
                )}
              </div>
            </div>
          ) : null}

          <div className='switch'>
            <label>
              <span
                className={`material-icons ${toAll ? 'send-email-to' : ''}`}
              >
                how_to_reg
              </span>

              <input
                className='checkbox-darkmode'
                type='checkbox'
                checked={toAll}
                onChange={() => setToAll(!toAll)}
              />
              <span className='lever'></span>

              <span
                className={`material-icons ${toAll ? '' : 'send-email-to'}`}
              >
                groups
              </span>
            </label>
          </div>
          <button
            className='chip theme-chip-button waves-effect waves-light right'
            onClick={() => {
              if (
                window.confirm(
                  `Are you ready to send this email ${
                    toAll ? 'to everyone' : 'only to subscribers'
                  }?`
                )
              ) {
                handleSendEmail()
              }
            }}
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ previewEmail, emailSent }) => {
  return { previewEmail, emailSent }
}

export default connect(mapStateToProps, actions)(Email)
