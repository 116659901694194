import React from 'react'
import { connect } from 'react-redux'
import List from '../UI/List'

const Customers = ({ users }) => {
  return (
    <List
      pageSize={10}
      columns={[
        { title: 'Email', field: 'email' },
        { title: 'Company', field: 'company' },
        {
          title: 'Requested Production',
          field: 'requestedProduction',
          lookup: {
            false: (
              <span
                className='material-icons red-text'
                style={{ marginRight: '20px' }}
              >
                fiber_manual_record
              </span>
            ),
            true: (
              <span
                className='material-icons green-text'
                style={{ marginRight: '20px' }}
              >
                fiber_manual_record
              </span>
            ),
          },
          customSort: (a, b) => a.active - b.active,
          align: 'center',
        },
        {
          title: 'Email Confirmed',
          field: 'emailConfirmed',
          lookup: {
            false: (
              <span
                className='material-icons red-text'
                style={{ marginRight: '20px' }}
              >
                fiber_manual_record
              </span>
            ),
            true: (
              <span
                className='material-icons green-text'
                style={{ marginRight: '20px' }}
              >
                fiber_manual_record
              </span>
            ),
          },
          customSort: (a, b) => a.active - b.active,
          align: 'center',
        },
        {
          title: 'Phone Confirmed',
          field: 'phoneNumberConfirmed',
          lookup: {
            false: (
              <span
                className='material-icons red-text'
                style={{ marginRight: '20px' }}
              >
                fiber_manual_record
              </span>
            ),
            true: (
              <span
                className='material-icons green-text'
                style={{ marginRight: '20px' }}
              >
                fiber_manual_record
              </span>
            ),
          },
          customSort: (a, b) => a.active - b.active,
          align: 'center',
        },
      ]}
      data={users}
    />
  )
}

const mapStateToProps = ({ users }) => {
  return { users }
}

export default connect(mapStateToProps)(Customers)
