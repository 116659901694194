import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import MaterialTable from 'material-table'
import { Link, useHistory } from 'react-router-dom'
import Spinner from './Spinner'
import M from 'materialize-css/dist/js/materialize.min.js'

const Table = ({
	pageSize,
	columns,
	data,
	title,
	isCustomerPage,
	deleteSandbox,
	actions,
	users,
	getAllUsers,
	postSandbox,
	updateAPIPods,
	getImageTags,
	availableTags
}) => {
	const [currentSandbox, setCurrentSandbox] = useState({
		id: 0,
		name: '',
		owner: '',
	})
	const [tableActions, setTableActions] = useState(null)
	const [newSandboxName, setNewSandboxName] = useState('')
	const [newSandboxDescription, setNewSandboxDescription] = useState('')
	const [newSandboxOwner, setNewSandboxOwner] = useState(0)
	const [newSandboxNameError, setNewSandboxNameError] = useState('')
	const [newSandboxDescriptionError, setNewSandboxDescriptionError] = useState('')
	const [newSandboxOwnerError, setNewSandboxOwnerError] = useState('')

	const getTags = useCallback(() =>{
		getImageTags()
	}, [getImageTags])
	


	useEffect(getTags, [])

	const [selectedBankserviceImageTag, setBankserviceImageTag] = useState("")
	const [selectedMigratorImageTag, setMigratorImageTag] = useState("")
	let history = useHistory()

	const handleRowClick = (_, object) => {
		let path,
			pathName = window.location.href

		if (isCustomerPage) {
			path = `/sandboxes/${object.sandboxId}`
		} else {
			if (pathName.includes('sandboxes'))
				path = `/sandboxes/${object.sandboxId}`
			else if (pathName.includes('customers')) path = `/customers/${object.id}`
		}

		history.push(path)
	}

	const handleDeleteClick = (e, object) => {
		let obj = {
			id: object.sandboxId,
			name: object.name,
			owner: object.owner.userName,
		}
		setCurrentSandbox(obj)

		const elem = document.getElementById('deleteModal')
		M.Modal.init(elem).open()
	}

	const deleteSandboxHandler = () => {
		deleteSandbox(currentSandbox.id)
	}

	const handleAddClick = () => {
		const elem = document.getElementById('addModal')
		M.Modal.init(elem).open()
	}

	const createSandboxHandler = (e) => {
		if (
			newSandboxName !== '' &&
			newSandboxDescription !== '' &&
			newSandboxOwner !== 0
		) {
			postSandbox(newSandboxOwner, {
				name: newSandboxName,
				description: newSandboxDescription,
			})
		} else {
			e.preventDefault()
			if (newSandboxName === '') setNewSandboxNameError('Required field.')
			else setNewSandboxNameError('')
			if (newSandboxDescription === '')
				setNewSandboxDescriptionError('Required field.')
			else setNewSandboxDescriptionError('')
			if (newSandboxOwner === 0) setNewSandboxOwnerError('Required field.')
			else setNewSandboxOwnerError('')
		}
	}

	

	const handleUpdateAPIClick = () => {		
		const elem = document.getElementById('updateAPIModal')
		const serviceSelectElems = document.querySelectorAll('.bankserviceTags')
		const migratorSelectElems = document.querySelectorAll('.migratorTags')
		M.Modal.init(elem).open()
		M.FormSelect.init(serviceSelectElems)
		M.FormSelect.init(migratorSelectElems)	
	}

	const handleBankserviceChange = e => {
		setBankserviceImageTag(e.target.value)
	} 
	const handleMigratorChange = e =>{
		setMigratorImageTag(e.target.value)
	}
	const updateAPIHandler = () => {
		if( selectedBankserviceImageTag !== "" && selectedMigratorImageTag !== ""){
			updateAPIPods(selectedBankserviceImageTag, selectedMigratorImageTag)
		}
	}

	useEffect(() => {
		M.AutoInit()
		if (actions === 'delete') {
			setTableActions([
				{
					icon: 'delete',
					tooltip: 'Delete',
					onClick: (e, object) => handleDeleteClick(e, object),
				},
				{
					icon: 'add_box',
					tooltip: 'New Sandbox',
					position: 'toolbar',
					onClick: () => handleAddClick(),
				},
				{
					icon: 'cloud_upload',
					tooltip: 'Change API image',
					position: 'toolbar',
					onClick: () => handleUpdateAPIClick()
				}
			])
		}
	}, [actions])

	return (users !== undefined && availableTags["bankservice"] !== undefined) ? (		
		<div className='mat-table'>
			<MaterialTable
				title={title}
				columns={columns}
				data={data}
				options={{
					sorting: true,
					headerStyle: {
						fontFamily: 'system-ui',
						fontWeight: '600',
					},
					emptyRowsWhenPaging: true,
					exportAllData: true,
					exportButton: true,
					pageSize: pageSize,
					pageSizeOptions: [pageSize, pageSize * 2, pageSize * 5],
					actionsColumnIndex: -1,
				}}
				actions={tableActions}
				style={{ padding: '10px' }}
				onRowClick={(e, object) => handleRowClick(e, object)}
			/>

			<div id='deleteModal' className='modal'>
				<div className='modal-content'>
					<h6>Delete Sandbox #{currentSandbox.id}</h6>
					<p style={{ marginTop: '30px' }}>
						Are you sure you want to delete the current sandbox?
					</p>
					<div className='row'>
						<div className='input-field col s6'>
							<input
								disabled
								value={currentSandbox.name}
								type='text'
								className='validate'
							/>
							<label className='active'>
								Name
							</label>
						</div>
						<div className='input-field col s6' style={{ marginTop: '15px' }}>
							<input
								disabled
								value={currentSandbox.owner}
								type='text'
								className='validate'
							/>
							<label className='active'>
								Owner
							</label>
						</div>
					</div>
				</div>
				<div className='modal-footer'>
					<Link
						to='#'
						onClick={deleteSandboxHandler}
						className='modal-close btn-small red'
						style={{ marginRight: '10px' }}
					>
						Delete
					</Link>
					<Link to='#' className='modal-close btn-flat'>
						Close
					</Link>
				</div>
			</div>

			<div id='updateAPIModal' className='modal col s6'>
				<form onSubmit={updateAPIHandler}>
				<div className='modal-content'>
					<div className='row'>
						<h6>Update API image for all sandboxes</h6>
						<div className="input-field col s6">							
							<select className="bankserviceTags" defaultValue="" onChange={handleBankserviceChange}>
								<option value="" disabled>Choose Bankservice Image</option>																					
								{availableTags["bankservice"].map(tag => (																		
								<option key={tag} value={tag}>
								 	{tag}
								</option>																	
								))}
							</select>
							<label>Available Bankservice Images</label>
						</div>
						
					</div>
					<div className='row'>
						<div className="input-field col s6">
							<select className="migratorTags" defaultValue="" onChange={handleMigratorChange}>
								<option value="" disabled>Choose Migrator Image</option>
								{availableTags["bankservice-migrator"].map(tag => (
									<option key={tag} value={tag}>
										{tag}
									</option>
								))}
							</select>
							<label>Available Migrator Images</label>
						</div>
					</div>
					<p>
						Are you sure you want to restart all sandbox pods and apply the selected images?
					</p>
				</div>
				<div className='modal-footer'>
					<button type='submit' className='btn-small modal-create-btn'>
						Restart Pods
					</button>
					<Link to='#' className='modal-close btn-flat'>
						Close
					</Link>
				</div>
				</form>
			</div>

			<div id='addModal' className='modal'>
				<form onSubmit={(e) => createSandboxHandler(e)}>
					<div className='modal-content modal-add'>
						<h6>Create a sandbox</h6>
						<br />
						<br />
						<div className='row'>
							<div className='input-field col s6'>
								<input
									id='name'
									type='text'
									className='validate'
									onChange={(e) => setNewSandboxName(e.target.value)}
								/>
								<span className='red-text'>{newSandboxNameError}</span>
								<label className='active' htmlFor='name'>
									Name
								</label>
							</div>
							<div className='input-field col s6'>
								<input
									id='description'
									type='text'
									className='validate'
									onChange={(e) => setNewSandboxDescription(e.target.value)}
								/>
								<span className='red-text'>{newSandboxDescriptionError}</span>
								<label className='active' htmlFor='description'>
									Description
								</label>
							</div>
						</div>
						<div className='row'>
							<div
								className='input-field col s12'
								style={{ marginBottom: '50px' }}
							>
								<select onChange={(e) => setNewSandboxOwner(e.target.value)}>
									<option value='' defaultValue>
										Choose your option
									</option>
									{users.map((user) => (
										<option key={user.id} value={user.id}>{user.userName}</option>
									))}
								</select>
								<span className='red-text'>{newSandboxOwnerError}</span>
								<label className='active'>Owner</label>
							</div>
						</div>
					</div>
					<div className='modal-footer'>
						<button type='submit' className='btn-small modal-create-btn'>
							Create
						</button>
						<Link to='#' className='modal-close btn-flat'>
							Close
						</Link>
					</div>
				</form>
			</div>
		</div>
	) : (
			<Spinner />
		)
}

const mapStateToProps = ({ users, availableTags }) => {
	return { users, availableTags}
}

export default connect(mapStateToProps, actions)(Table)
