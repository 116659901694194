import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Chip from '../UI/Chip'
import * as actions from '../../store/actions'

const NewestCustomerCard = ({
	sandboxes,
	currentSandbox,
	users,
	getSandbox,
}) => {
	const history = useHistory()
	const [sandboxExists, setSandboxExists] = useState(true)
	const user = users.reduce((a, b) => {
		return a.createdDate > b.createdDate ? a : b
	})

	useEffect(() => {
		const [sandbox] = sandboxes.filter((s) => s.owner.userId === user.id)
		if (!sandbox) setSandboxExists(false)
		else getSandbox(sandbox.sandboxId)
	}, [getSandbox, sandboxes, user])

	return (
		<div
			className='card-panel card-underlined w-100 hoverable'
			onClick={() => history.push(`/customers/${user.id}`)}
		>
			<div className='row'>
				<div className='col s6'>
					<h6>Newest Customer</h6>
				</div>
				<div className='col s6'>
					<h6 className='right-align'>
						{moment(user.createdDate).format('MMMM Do YYYY, h:mm:ss')}
					</h6>
				</div>
			</div>
			<blockquote className='blockquote'>{user.company}</blockquote>
			<div className='card-chips'>
				{!sandboxExists && (
					<>
						<Chip text='Email' error={!user.emailConfirmed} />
						<Chip text='Phone Number' error={!user.phoneNumberConfirmed} />
						<Chip text='Provisioned' error />
						<Chip text='Database' error />
						<Chip text='Certificate' error />
						<Chip text='Alive' error />
					</>
				)}
				{currentSandbox && (
					<>
						<Chip text='Email' error={!user.emailConfirmed} />
						<Chip text='Phone Number' error={!user.phoneNumberConfirmed} />
						<Chip text='Provisioned' error={!currentSandbox.provisioned} />
						<Chip
							text='Database'
							error={!currentSandbox.sandboxStatus.dbHasData}
						/>
						<Chip
							text='Certificate'
							error={!currentSandbox.sandboxStatus.hasCertificate}
						/>
						<Chip text='Alive' error={!currentSandbox.sandboxStatus.podAlive} />
					</>
				)}
				{sandboxExists && !currentSandbox && <Chip text='Loading..' />}
			</div>
		</div>
	)
}

const mapStateToProps = ({ sandboxes, currentSandbox, users }) => {
	return { sandboxes, currentSandbox, users }
}

export default connect(mapStateToProps, actions)(NewestCustomerCard)
