import './App.scss'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Dashboard from './dashboard/Controller'
import Sandboxes from './sandboxes/Controller'
import DetailedSandbox from './sandboxes/DetailedSandbox'
import DetailedSandboxActivity from './sandboxes/DetailedSandboxActivity'
import DetailedCustomer from './customers/DetailedCustomer'
import Customers from './customers/Controller'
import Email from './email/Controller'
import AuthCallback from '../tools/AuthCallback'
import CheckAuth from '../tools/CheckAuth'
import NotFound from './UI/NotFound'
import Navbar from './UI/Navbar'
import Footer from './UI/Footer'

const App = () => {
	return (
		<Router>
			<CheckAuth>
				<Navbar />
				<Switch>
					<Route exact path='/' component={Dashboard} />
					<Route exact path='/sandboxes' component={Sandboxes} />
					<Route exact path='/sandboxes/:id' component={DetailedSandbox} />
					<Route exact path='/sandboxes/:id/detailed-activity' component={DetailedSandboxActivity}/>
					<Route exact path='/customers' component={Customers} />
					<Route exact path='/customers/:id' component={DetailedCustomer} />
					<Route exact path='/email' component={Email} />
					<Route exact path='/oidc-callback' component={AuthCallback} />
					<Route path='*' component={NotFound} />
				</Switch>
				<Footer />
			</CheckAuth>
		</Router>
	)
}

export default App
