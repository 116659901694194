import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'

const MenuLink = ({ name, currentTab, changeTab }) => {
	const id = name.toLowerCase()
	return (
		<div className='col s2'>
			<Link
				to={`#${id}`}
				id={id}
				className={`container-submenu ${
					id === currentTab ? 'container-active' : ''
				}`}
				onClick={(e) => changeTab(e.target.id)}
			>
				{name}
			</Link>
		</div>
	)
}

const mapStateToProps = ({ currentTab }) => {
	return { currentTab }
}

export default connect(mapStateToProps, actions)(MenuLink)
