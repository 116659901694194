import { createUserManager } from 'redux-oidc'

const userManagerConfig = {
	client_id: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
	redirect_uri: `${process.env.REACT_APP_SANDBOX_ADMIN_ME_URL}/oidc-callback`,
	response_type: 'code', 
	scope: `openid profile email ${process.env.REACT_APP_AZURE_AD_SCOPE}`,
	authority: `${process.env.REACT_APP_AZURE_AD_AUTHORITY}/v2.0`, 
	silent_redirect_uri: `${window.location.origin}/oidc-silent-renew`,
	automaticSilentRenew: true, 
	filterProtocolClaims: true,
	loadUserInfo: false,
	metadata: {
		issuer: `${process.env.REACT_APP_AZURE_AD_AUTHORITY}/v2.0`,
		authorization_endpoint: `${process.env.REACT_APP_AZURE_AD_AUTHORITY}/oauth2/v2.0/authorize`,
		token_endpoint: `${process.env.REACT_APP_AZURE_AD_AUTHORITY}/oauth2/v2.0/token`,
		userinfo_endpoint: 'https://graph.microsoft.com/oidc/userinfo',
		end_session_endpoint: `${process.env.REACT_APP_AZURE_AD_AUTHORITY}/oauth2/v2.0/logout`
	  },
	post_logout_redirect_uri: `${window.location.origin}/`, 
	};

const userManager = createUserManager(userManagerConfig)

export default userManager
