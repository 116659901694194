import { PREVIEW_EMAIL, PREVIEW_EMAIL_ERROR } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case PREVIEW_EMAIL:
      return { email: action.payload.trim() }
    case PREVIEW_EMAIL_ERROR:
      return { error: 'Could not create email template' }
    default:
      return state
  }
}
